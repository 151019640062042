import React, { useEffect, useState } from "react";
import stores from "../../../stores";
import { Provider } from "mobx-react";
import AuthUserService from "../../../services/AuthUserService";
import { ToastContainer } from "react-toastify";
import PageLayout from "../PageLayout";
import jwt_decode from "jwt-decode";

const RootLayout = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { children } = props;
  useEffect(() => {
    AuthUserService.subscribeAuthChange(async user => {
      if (user) {
        const {
          accessToken
          // email,
          // uid,
          // refreshToken,
          // emailVerified,
          // displayName,
          // isAnonymous
        } = user;
        const decodedToken = jwt_decode(accessToken);
        if (decodedToken) {
          const { roles, aud, exp, iat } = decodedToken || {};
          const additionalData = { roles, aud, exp, iat };
          stores.auth.setUser({ ...user, ...additionalData });
        } else {
          stores.auth.setUser(user);
        }
        // ако вече е логнат добавяме юзъра в state
        setLoggedIn(true);
      } else {
        // ако не е логнат създаваме анонимен потребител
        try {
          await AuthUserService.loginAnonymous();
          setLoggedIn(true);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  }, [loggedIn]);
  return (
    <Provider {...stores}>
      {loggedIn ? children : <PageLayout loading />}
      <ToastContainer />
    </Provider>
  );
};

export default RootLayout;
